import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static targets = ['sendTo', 'phiMessage'];

  connect() {
    super.connect();
    this.sendToTarget.addEventListener('change', this.togglePhiMessage.bind(this));
  }

  disconnect() {
    super.disconnect();
    this.sendToTarget.removeEventListener('change', this.togglePhiMessage.bind(this));
  }

  togglePhiMessage() {
    if (this.sendToTarget.value == 2) {
      this.phiMessageTarget.classList.add('hidden');
    } else {
      this.phiMessageTarget.classList.remove('hidden');
    }
  };
}
