import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import * as popper from '@popperjs/core';
import * as moment from 'moment';

import "chartkick/dist/chartkick";
import 'tooltipster/dist/js/tooltipster.bundle.min';
import "@fortawesome/fontawesome-free/js/all.js";
import "./vendors/jquery-dom-line.js";
import "./vendors/signature.js";

//import './application';

Rails.start();
Turbolinks.start();
ActiveStorage.start();
