import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static targets = [
    'address1',
    'address2',
    'city',
    'stateId',
    'zipcode',
    'submitBtn'
  ];

  connect() {
    super.connect();

    document.addEventListener('store-members-portal-ship-address-validation-enable-submit', this.enableSubmit.bind(this));
    document.addEventListener('store-members-portal-ship-address-validation-disable-submit', this.disableSubmit.bind(this));
    document.addEventListener('store-members-portal-ship-address-validation-bypass', this.bypassValidation.bind(this));
    document.addEventListener('store-members-portal-ship-address-validation-display-address-errors', this.displayAddressErrors.bind(this));

    this.addInputEventListeners();
    this.toggleButtonEnabled();
  }

  disconnect() {
    super.disconnect();

    document.removeEventListener('store-members-portal-ship-address-validation-enable-submit', this.enableSubmit.bind(this));
    document.removeEventListener('store-members-portal-ship-address-validation-disable-submit', this.disableSubmit.bind(this));
    document.removeEventListener('store-members-portal-ship-address-validation-bypass', this.bypassValidation.bind(this));
    document.removeEventListener('store-members-portal-ship-address-validation-display-address-errors', this.displayAddressErrors.bind(this));

    this.removeInputEventListeners();
    delete window.shipAddressLookupBypassed;
    delete window.shipAddressValidatedWithSmarty;
  }

  submitForm(event) {
    event && event.preventDefault();

    if (!(this.addressValuesPresent() && this.addressValuesValid()) || window.shipAddressLookupBypassed) {
      this.element.removeAttribute('data-action');
      this.submitToReflex()
    } else {
      this.validateAddress();
      window.shipAddressValidatedWithSmarty = true;
      this.address1Target.parentNode.querySelector('p.text-error').innerHTML = '';
    }
  }

  submitToReflex() {
    this.stimulate('Store::MembersPortal::ConfirmShipAddressReflex#confirm_ship_address_details', { serializeForm: true })
  }

  validateAddress() {
    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          'Store::MembersPortal::ShipAddressReflex#validate',
          this.address1Target.value,
          this.address2Target.value,
          this.cityTarget.value,
          this.stateIdTarget.value,
          this.zipcodeTarget.value,
          window.shipAddressValidatedWithSmarty || false
        )
      }
    })
  }

  clearRepeatAddressValidation() {
    window.shipAddressLookupBypassed = false;
    window.shipAddressValidatedWithSmarty = false;
    this.toggleButtonEnabled();
  }

  toggleButtonEnabled() {
    this.addressValuesPresent() && this.addressValuesValid() ? this.enableSubmit() : this.disableSubmit();
  }

  addressValuesPresent() {
    return this.formInputs().every(element => element.value);
  }

  addressValuesValid() {
    const selectedLabel = this.stateIdTarget?.selectedOptions[0]?.label
    return this.stateIdTarget.value && /Not Available/g.test(selectedLabel) === false;
  }

  formInputs() {
    return [this.address1Target, this.cityTarget, this.stateIdTarget, this.zipcodeTarget];
  }

  enableSubmit() {
    this.submitBtnTarget.classList.remove('cursor-not-allowed');
    this.submitBtnTarget.removeAttribute('disabled');
  }

  disableSubmit() {
    this.submitBtnTarget.classList.add('cursor-not-allowed');
    this.submitBtnTarget.setAttribute('disabled', 'disabled');
  }

  bypassValidation(event) {
    const { skipAddressLookupValidation } = event.detail;
    // document.getElementById('address_lookup_validation_skipped').value = skipAddressLookupValidation;
    window.shipAddressLookupBypassed = true;
    // this.submitForm();
    this.submitToReflex()
  }

  displayAddressErrors(event) {
    const parentOfFieldElement = this.address1Target.parentNode;
    const { errorMessage } = event.detail;

    this.address1Target.focus();
    parentOfFieldElement.querySelector('p.text-error').innerHTML = errorMessage;
  }

  addInputEventListeners() {
    this.formInputs().forEach(input => {
      input.addEventListener('change', this.toggleButtonEnabled.bind(this));
      input.addEventListener('input', this.toggleButtonEnabled.bind(this));
    });
  }

  removeInputEventListeners() {
    this.formInputs().forEach(input => {
      input.removeEventListener('change', this.toggleButtonEnabled.bind(this));
      input.removeEventListener('input', this.toggleButtonEnabled.bind(this));
    });
  }

  selectOptionalSettingOption(event) {
    const selected = event.target;
    const eventValue = selected.checked;

    selected
      .closest("div.optional-address-settings")
      .querySelectorAll("input[type=checkbox]")
      .forEach((element) => element.checked = false)

    selected.checked = eventValue;
  }
}
