import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  setCancelAccountDetailsHalted() {
    this.callStimulusAction({
      cb: () => {
        this.stimulate('Store::MembersPortal::CancelAccount#reach_out_to_cc');
      },
    });
  }

  connect() {
    super.connect();
  }

  disconnect() {
    super.disconnect();
  }

  reasonSelected(e) {
    e.target.setAttribute('data-reason-selected', true);
    const otherReason = document.getElementById('other-reason');
    const overrideBtn = document.getElementById('override-btn');

    if (e.target.dataset.isOtherOption === 'true') {
      otherReason.classList.remove('hidden');
      otherReason.setAttribute('required', 'true');
      otherReason.addEventListener('input', this.toggleSubmitBtn.bind(this, overrideBtn));
      this.disableSubmitBtn();
    } else {
      otherReason.value = '';
      otherReason.removeAttribute('required');
      otherReason.classList.add('hidden');
      otherReason.removeEventListener('input', this.toggleSubmitBtn.bind(this, overrideBtn));

      if (!overrideBtn) {
        this.enableSubmitBtn();
      } else if (overrideBtn.dataset.override) {
        this.enableSubmitBtn();
      }
    }
  }

  enableSubmitBtn() {
    const submitBtn = document.getElementById('submit-btn');
    submitBtn.classList.remove('cursor-not-allowed');
    submitBtn.disabled = false;
  }

  disableSubmitBtn() {
    const submitBtn = document.getElementById('submit-btn');
    submitBtn.classList.add('cursor-not-allowed');
    submitBtn.disabled = true;
  }

  toggleSubmitBtn(overrideBtn = null, e) {
    if (!overrideBtn) {
      e.target.value.length ? this.enableSubmitBtn() : this.disableSubmitBtn();
    } else if (overrideBtn.dataset.override) {
      this.enableSubmitBtn();
    }
  }

  setOverrideAttribute(e) {
    e.target.dataset.override = true;
    if ($('input[data-reason-selected="true"]').length) this.enableSubmitBtn();
  }
}
