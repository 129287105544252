import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static targets = [
    'entered',
    'smarty',
  ]
  static values = {
    enteredAddress1: String,
    enteredAddress2: String,
    enteredCity: String,
    enteredState: String,
    enteredZipcode: String,
    smartyAddress1: String,
    smartyAddress2: String,
    smartyCity: String,
    smartyState: String,
    smartyZipcode: String,
    primaryAddress: Boolean,
    oneTimeUseAddress: Boolean,
    choseSmarty: { type: Boolean, default: false },
    isPrimaryAddress: { type: Boolean, default: true },
    isOneTimeUseAddress: { type: Boolean, default: false },
  };

  connect() {
    super.connect();
    if (this.hasSmartyTarget){
      this.smartyAddress1Value = this.smartyTarget.dataset["store-MembersPortal-AddressConfirmationSmartyAddress1Value"]
      this.smartyAddress2Value = this.smartyTarget.dataset["store-MembersPortal-AddressConfirmationSmartyAddress2Value"]
      this.smartyCityValue = this.smartyTarget.dataset["store-MembersPortal-AddressConfirmationSmartyCityValue"]
      this.smartyStateValue = this.smartyTarget.dataset["store-MembersPortal-AddressConfirmationSmartyStateValue"]
      this.smartyZipcodeValue = this.smartyTarget.dataset["store-MembersPortal-AddressConfirmationSmartyZipcodeValue"]
    }

    if (this.hasEnteredTarget){
      this.enteredAddress1Value = this.enteredTarget.dataset["store-MembersPortal-AddressConfirmationEnteredAddress1Value"]
      this.enteredAddress2Value = this.enteredTarget.dataset["store-MembersPortal-AddressConfirmationEnteredAddress2Value"]
      this.enteredCityValue = this.enteredTarget.dataset["store-MembersPortal-AddressConfirmationEnteredCityValue"]
      this.enteredStateValue = this.enteredTarget.dataset["store-MembersPortal-AddressConfirmationEnteredStateValue"]
      this.enteredZipcodeValue = this.enteredTarget.dataset["store-MembersPortal-AddressConfirmationEnteredZipcodeValue"]
    }
  }

  submit(event) {
    event.preventDefault();
    let payload = this.payload();
    this.callStimulusAction({
        cb: () => {
          this.stimulate("Store::MembersPortal::ConfirmShipAddressReflex#commit", payload)
        }
      }
    )
  }

  payload() {
    let payload = {}
    if (this.choseSmartyValue) {
      payload = {
        address1: this.smartyAddress1Value,
        address2: this.smartyAddress2Value,
        city: this.smartyCityValue,
        state_id: this.smartyStateValue,
        zipcode: this.smartyZipcodeValue,
      }
    }
    else {
      payload = {
        address1: this.enteredAddress1Value,
        address2: this.enteredAddress2Value,
        city: this.enteredCityValue,
        state_id: this.enteredStateValue,
        zipcode: this.enteredZipcodeValue,

      }
    }

    return {
      additional_address: payload,
      primary_address: this.isPrimaryAddressValue,
      one_time_use_address: this.isOneTimeUseAddressValue,
    }
  }

  selectAddressOption(e){
    this.choseSmartyValue = e.target.dataset["isSmarty"];
  }

}
