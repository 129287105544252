import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  static targets = ['pause', 'option', 'otherTextField'];

  connect() {
    super.connect();
  }

  onPauseClick(e) {
    if (e.currentTarget.dataset.isPaused === 'true') return;
    $('.pause-reasons').toggleClass('hidden');
    $('.change-subscription').toggleClass('hidden');
    $('.title-and-subtitle').toggleClass('hidden');
    this.toggleSubmitBtn();
  }

  onOptionTargetClick(e) {
    if (e.currentTarget.dataset.showTextField === 'true') {
      // Disable submit button if "Other" option is selected
      this.disableSubmitBtn();

      // Unhide text field for "Other" option
      // Enable/disable submit button when text is entered/removed
      const _this = this;
      $(this.otherTextFieldTarget)
        .removeClass('hidden')
        .prop('required', true)
        .on('input', function () { $(this).val().length > 0 ? _this.enableSubmitBtn() : _this.disableSubmitBtn(); });
    } else {
      // Hide text field for "Other" option
      $(this.otherTextFieldTarget)
        .addClass('hidden')
        .removeProp('required');
      this.enableSubmitBtn();
    }
  }

  toggleSubmitBtn() {
    $('#submitBtn').attr('disabled') ? this.enableSubmitBtn() : this.disableSubmitBtn();
  }

  enableSubmitBtn() {
    $('#submitBtn')
      .prop('disabled', false)
      .removeClass('hover:cursor-not-allowed hover:bg-opacity-75');
  }

  disableSubmitBtn() {
    $('#submitBtn')
      .prop('disabled', true)
      .addClass('hover:cursor-not-allowed hover:bg-opacity-75');
  }

  submit(e) {
    const reason = $('.pause-reasons').find('input[type=radio]:checked');
    if (reason.val() !== 'Other: Please explain.') return;

    // Add text from "Other" option text field to the radio button input field
    e.preventDefault();
    reason.val(`Other: ${$(this.otherTextFieldTarget).val()}`);
    $(e.currentTarget).submit();
  }
}
