import Toastify from 'toastify-js';
import ApplicationController from '../../application_controller';

export default class Lead extends ApplicationController {
  static targets = ['activeTab', 'copyEmail', 'copyPhone', 'salesRepSelect', 'message'];

  connect() {
    super.connect();
  }

  reflexSuccess(_, reflex) {
    if (reflex === 'Admin::Crm::LeadReflex#create_note') {
      this.showToast({
        text: 'Note successfully created!',
      });
    }

    if (reflex === 'Admin::Crm::LeadReflex#update') {
      this.showToast({
        text: 'Updated touches successfully!',
      });
    }

    if (reflex === 'Admin::Crm::CallLogHistoryFormReflex#create') {
      this.clearFields();
    }

    if (reflex === 'Admin::Crm::LeadReflex#reply_to_email') {
      const body = document.getElementById('crm_email_body');
      body.setSelectionRange(0, 0);
      body.focus();
      body.style.height = '1px';
      body.style.height = `${25 + body.scrollHeight}px`;
    }
  }

  resetStyles() {
    const elements = document.querySelectorAll('[data-tab]');

    elements.forEach((element) => {
      element.classList.remove('text-blue-700', 'border-blue-700');
    });
  }

  clearFields() {
    // Select all input fields that are text, textarea or select fields
    const inputs = document.querySelectorAll(
      '.clearable input[type="text"], .clearable textarea, .clearable select',
    );

    // Loop through the inputs and clear their values
    inputs.forEach((input) => {
      const inputField = input;
      inputField.value = '';
    });
  }

  async copyToClipboard(event) {
    const { field } = event.currentTarget.dataset;
    const copiedText = this[`copy${field}Target`].textContent;

    try {
      await navigator.clipboard.writeText(copiedText);

      Toastify({
        text: `Copied ${copiedText} to clipboard!`,
        duration: 1000,
        close: true,
        gravity: 'top',
        position: 'center',
        stopOnFocus: true,
        style: {
          background: 'linear-gradient(to right,  #0859A6, #0971D3)',
        },
      }).showToast();
    } catch (error) {
      Toastify({
        text: `There was an error copying ${copiedText} to clipboard. Please try again.`,
        duration: 1000,
        close: true,
        gravity: 'top',
        position: 'center',
        stopOnFocus: true,
        style: {
          background: '#d41111',
        },
      }).showToast();
    }
  }

  addNote(event) {
    this.stimulate(
      'Admin::Crm::LeadReflex#new_note_form',
      event.currentTarget.dataset.currentIndex,
    );
  }

  assignSalesRep() {
    this.stimulate(
      'Admin::Crm::AssignRepModalReflex#assign_rep',
      this.salesRepSelectTarget.value,
    );
  }

  toggleRetentionStatus(e) {
    const winBtn = document.getElementById('win-button');
    const loseBtn = document.getElementById('lose-button');
    const winCss = ['bg-green-win', 'border-green-win', 'text-white'];
    const loseCss = ['bg-red-600', 'border-red-600', 'text-white'];

    if (e.currentTarget.dataset.status === 'win') {
      winBtn.classList.add(...winCss);
      loseBtn.classList.remove(...loseCss);
    } else {
      loseBtn.classList.add(...loseCss);
      winBtn.classList.remove(...winCss);
    }
  }
}
