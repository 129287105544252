import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.removeStoredFieldValues();
  }

  removeStoredFieldValues() {
    [
      'checkout_form_ship_address_attributes_address1',
      'checkout_form_ship_address_attributes_address2',
      'checkout_form_ship_address_attributes_city',
      'checkout_form_ship_address_attributes_zipcode'
    ].forEach(function(fieldId){
      if (localStorage.getItem(fieldId)) {
        localStorage.removeItem(fieldId);
      }
    })
  }
}
