/*
Used to force the CSRF tokens / Auth to be refreshed.
Simply connect the controller to the page and dispatch the event: `refreshCSRFToken`
*/

import ApplicationController from '../application_controller';
import Rails from '@rails/ujs'

export default class extends ApplicationController {
  connect() {
    super.connect();

    document.addEventListener('refreshCSRFToken', Rails.refreshCSRFTokens);
  }

  disconnect() {
    super.disconnect();

    document.removeEventListener('refreshCSRFToken', Rails.refreshCSRFTokens);
  }
}
