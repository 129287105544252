export function showLoadingSpinner() {
  document.body.classList.add('wait');
  const spinner = document.querySelector('.spinner');
  spinner.classList.remove("invisible", "opacity-0");
  spinner.classList.add("opacity-90", "bg-cream");
  spinner.style.zIndex = 1;
}

export function hideLoadingSpinner() {
  document.body.classList.remove('wait');
  const spinner = document.querySelector('.spinner');
  spinner.classList.add("invisible", "opacity-0");
}
