import ApplicationController from '../../application_controller';
import { scrollToElement } from '../../utilities/scrolling';

export default class extends ApplicationController {
  static targets = [
    'address1',
    'address2',
    'city',
    'state',
    'zipcode',
    'form',
    'submitBtn'
  ];
  validStates = this.stateTarget.dataset.validStates;

  connect() {
    super.connect();

    document.addEventListener('store-checkout-address-validation-bypass', this.bypassValidation.bind(this));
    document.addEventListener('store-checkout-address-validation-enableSubmit', this.enableSubmit.bind(this));
    document.addEventListener('store-checkout-address-validation-display-address-errors', this.displayAddressErrors.bind(this));
    this.stateTarget.addEventListener('change', this.validateServiceableState.bind(this));

    this.addStoredFieldValues();
    this.addInputEventListeners();
    this.toggleButtonEnabled();
  }

  disconnect() {
    super.disconnect();

    document.removeEventListener('store-skip-checkout-address-validation', this.bypassValidation.bind(this));
    document.removeEventListener('store-checkout-address-validation-enableSubmit', this.enableSubmit.bind(this));
    document.removeEventListener('store-checkout-address-validation-display-address-errors', this.displayAddressErrors.bind(this));
    this.stateTarget.removeEventListener('change', this.validateServiceableState.bind(this));

    this.removeInputEventListeners();
    delete window.storeCheckoutSkipAddressLookup;
    delete window.storeCheckoutValidatedCurrentValues;
  }

  submitForm(event) {
    event && event.preventDefault();

    if (!this.addressValuesPresent() || window.storeCheckoutSkipAddressLookup) {
      this.formTarget.removeAttribute('data-action');
      this.formTarget.submit();
    } else {
      this.removeBypassedHiddenField();
      this.validateAddress();
      window.storeCheckoutValidatedCurrentValues = true;
      this.address1Target.parentNode.querySelector('p.text-error').innerHTML = '';
    }
  }

  addressValuesPresent() {
    return this.address1Target.value &&
      this.cityTarget.value &&
      this.stateTarget.value &&
      this.zipcodeTarget.value
  }

  validateAddress() {
    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          'Store::Cart::CheckoutAddressReflex#validate',
          this.address1Target.value,
          this.address2Target.value,
          this.cityTarget.value,
          this.stateTarget.value,
          this.zipcodeTarget.value,
          window.storeCheckoutValidatedCurrentValues || false
        )
      }
    })
  }

  bypassValidation(event) {
    const { logSkip } = event.detail;
    if (logSkip) this.addBypassedHiddenField();
    window.storeCheckoutSkipAddressLookup = true;
    this.submitForm();
  }

  addBypassedHiddenField() {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'checkout_form[skip_address_lookup]');
    input.setAttribute('value', 'true');
    input.setAttribute('id', 'skip_address_lookup_field');
    this.formTarget.appendChild(input);
  }

  removeBypassedHiddenField() {
    const hiddenField = document.getElementById('skip_address_lookup_field')
    if (hiddenField) hiddenField.remove();
  }

  enableSubmit() {
    scrollToElement(this.address1Target.parentNode);
    this.toggleButtonEnabled();
  }

  clearRepeatAddressValidation() {
    window.storeCheckoutValidatedCurrentValues = false;
    this.toggleButtonEnabled();
  }

  formInputs() {
    return Array.from(this.formTarget.querySelectorAll('input[type=text]:not(#checkout_form_ship_address_attributes_address2), option:checked'));
  }

  toggleButtonEnabled() {
    if (this.formInputs().every(input => input.value) && this.serviceableStateSelected()) {
      this.enableSubmitButton();
    } else {
      this.disableSubmitButton();
    }
  }

  enableSubmitButton() {
    this.submitBtnTarget.removeAttribute('disabled');
    this.submitBtnTarget.classList.remove('cursor-not-allowed');
  }

  disableSubmitButton() {
    this.submitBtnTarget.setAttribute('disabled', 'disabled');
    this.submitBtnTarget.classList.add('cursor-not-allowed');
  }

  addInputEventListeners() {
    this.formInputs().forEach(input => {
      input.addEventListener('change', this.toggleButtonEnabled.bind(this));
      input.addEventListener('input', this.toggleButtonEnabled.bind(this));
    })
  }

  removeInputEventListeners() {
    this.formInputs().forEach(input => {
      input.addEventListener('change', this.toggleButtonEnabled.bind(this));
      input.addEventListener('input', this.toggleButtonEnabled.bind(this));
    })
  }

  storeFieldValues(event) {
    const element = event.currentTarget;
    localStorage.setItem(element.id, element.value);
  }

  addStoredFieldValues() {
    this.formFieldsForLocalstorage().forEach((target) => {
      const value = localStorage.getItem(target.id);
      if (value) target.value = value;
    })
  }

  formFieldsForLocalstorage() {
    return [
      this.address1Target,
      this.address2Target,
      this.cityTarget,
      this.zipcodeTarget
    ];
  }

  displayAddressErrors(event) {
    const parentOfFieldElement = this.address1Target.parentNode;
    const { errorMessage } = event.detail;

    scrollToElement(parentOfFieldElement);
    parentOfFieldElement.querySelector('p.text-error').innerHTML = errorMessage;
  }

  validateServiceableState() {
    if (this.serviceableStateSelected()) {
      this.toggleButtonEnabled();
    } else {
      this.disableSubmitButton();
      this.stimulate('Store::Cart::CheckoutAddress#unserviceable_state');
    }
  }

  serviceableStateSelected() {
    if (!this.stateTarget.value) return false;

    return this.validStates.includes(this.stateTarget.value);
  }
}
