import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = [
    "form",
    "password",
    "confirmPassword",
    "passwordIcon",
    "confirmPasswordIcon",
    "phoneField",
    "dobField",
    "zipField",
  ];

  connect() {
    super.connect();
  }

  initialize() {
    if (this.hasFormTarget) {
      $(this.formTarget).on("submit", (e) => {
        e.preventDefault();
        $(this.phoneFieldTargets).unmask();
        this.formTarget.submit();
      });
    }

    if (this.hasDobFieldTarget) {
      $(this.dobFieldTarget).mask("00/00/0000");
    }

    if (this.hasPhoneFieldTarget) {
      $(this.phoneFieldTargets).mask("(000) 000-0000");
    }

    if (this.hasZipFieldTarget) {
      $(this.zipFieldTarget).mask("00000");
    }
  }

  togglePassword() {
    this.toggleInput(this.passwordTarget, this.passwordIconTarget);
  }

  toggleConfirmPassword() {
    this.toggleInput(
      this.confirmPasswordTarget,
      this.confirmPasswordIconTarget
    );
  }

  toggleInput(input, img) {
    if (input.type === "password") {
      input.type = "text";
      img.src = img.dataset.visible;
    } else {
      input.type = "password";
      img.src = img.dataset.blocked;
    }

    input.focus();
  }
}
